import { DbId } from '../types/types';

export default class Endpoints {
	static login = '/v1/token/';

	static logout = '/v1/auth/logout/';

	static forgotPassword = '/v1/reset-password/';

	static resetPassword = '/v1/password/reset/confirm/';

	static register = '/v1/register/';

	static useTerms = '/v1/terms_of_use/';

	static dashboard = '/v1/dashboard/';

	static product = '/v1/product/';

	static productPhotos = '/v1/produt_photos/';

	static productCategory = '/v1/produt_category/';

	static productSection = '/v1/sections/';

	static order = '/v1/order/';

	static adesao = '/v1/employee/';

	static adesaoContas = '/v1/employee-bill/';

	static shop = '/v1/product/';

	static user = '/v1/users/';

	static productShareStock = (id: DbId) => `/v1/product/${id}/share/`;

	static clients = '/v1/clients';

	static employee = '/v1/employee';

	static cleanEmployeeImport = '/v1/clean-employee-import';

	static employeeImport = '/v1/employee-import';

	static changePassword = '/v1/change-password/';

	static confirmData = '/v1/confirm-data';

	static createConfirmData = '/v1/create-confirm-data';

	static publicEmployeeBillUpdate = '/v1/employeebill-update/';

	static cidades = (uf: string) =>
		`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`;
}
