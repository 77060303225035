import { useState, useEffect, FC, ReactElement } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Form as RHForm, SelectInput, TextInput } from '@/components';
import { useModal } from '@/hooks';
import ModalMessage from '@/components/Modal/modalMessage';
import useConfirmData from './hooks/useConfirmData';
import { t } from 'i18next';
import { ConfirmacaoTokenTypeEnum } from '@/config/enum';

interface Props {
	token: string;
	tokenType: string;
}

const CreateFormConfirm: FC<Props> = ({ token, tokenType }) => {
	const { isOpen, className, openModalWithClass, closeModal } = useModal();
	const [hasErrors, setHasErrors] = useState<boolean>(false);
	const {
		clients,
		getClients,
		errors,
		createConfirmSchema,
		waitingApi,
		createConfirmData,
	} = useConfirmData();

	useEffect(() => {
		if (errors !== undefined && Object.keys(errors).length !== 0) {
			const invalidInputs = document.querySelectorAll('.is-invalid');
			if (invalidInputs.length > 0) {
				invalidInputs[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
			setTimeout(() => {
				setHasErrors(true);
				openModalWithClass('danger');
			}, 1000);
		} else {
			setHasErrors(false);
		}
	}, [errors]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await getClients();
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);

	const handleSubmit = async (data: any) => {
		const result = await createConfirmData(data);
		if (result != null) {
			window.location.href = `/confirmacao/${result.token}`;
		}		
	}

	const defaultValues: any = {
		client: tokenType === ConfirmacaoTokenTypeEnum.Client ? parseInt(token) : null,
	};
	let clientOptions: Array<ReactElement> = [
		<option value="">
			Carregando...
		</option>
	];
	if (clients != null && clients.length > 0) {
		if (tokenType === ConfirmacaoTokenTypeEnum.Client) {
			const intToken = parseInt(token);
			const client = clients.find((c) => c.id === intToken);
			clientOptions = [
				<option
					key={client.id}
					value={client.id}
					defaultChecked={true}
				>
					{client.corporate_reason}
				</option>
			];
		} else {
			clientOptions = [
				<option value="">
					Selecione
				</option>,
				...clients.map((client) => (
					<option
						key={client.id}
						value={client.id}
					>
						{client.corporate_reason}
					</option>
				)),
			];
		}
	}

	return (
		<>
			<RHForm
				onSubmit={handleSubmit}
				schema={createConfirmSchema}
				defaultValues={defaultValues}
			>
				<div className="inputs mt-2">
					<TextInput
						type="text"
						name="email"
						label="E-mail"
						placeholder="email@exemplo.com"
						containerClass={'mb-2'}
					/>
					<TextInput
						type="text"
						name="name"
						label="Nome Completo"
						placeholder="João da Silva"
						containerClass={'mb-2'}
					/>
					<SelectInput
						label="Local de Trabalho"
						name="client"
						className="react-select"
						containerClass="mb-2"
						disabled={clientOptions.length === 1}
					>
						
						{clientOptions}
					</SelectInput>
					{errors && <h4 className="red text-center">{errors['error']}</h4>}
				</div>

				<div className="checkbox_btn mt-3">
					<Button
						id="btnSubmit"
						type="submit"
						variant="btnColor"
						disabled={hasErrors || waitingApi}
					>
						{waitingApi ? (
							<Spinner className="text-primary m-2" color="primary" size={'sm'} />
						) : (
							<>Vamos Começar!</>
						)}
					</Button>
				</div>
			</RHForm>
			<ModalMessage
				isOpen={isOpen}
				toggleModal={className == 'success' ? () => {} : closeModal}
				className={className}
				title={
					className == 'success'
						? t('pages.cliente.gestaoColaboradores.form.modal.success')
						: t('pages.cliente.gestaoColaboradores.form.modal.error')
				}
				errors={errors}
			/>
		</>
	);
};

export default CreateFormConfirm;
