import { ClientErrors } from '@/types';
import classNames from 'classnames';
import { Button, Modal } from 'react-bootstrap';

type Props = {
	isOpen?: boolean;
	toggleModal?: () => any;
	className?: string;
	title?: string;
	errors?: ClientErrors;
	content?: string;
	customButtons?: any;
};

export default function ModalMessage({
	isOpen = false,
	toggleModal,
	className,
	title,
	errors,
	content,
	customButtons,
}: Props) {
	return (
		<Modal show={isOpen} onHide={toggleModal}>
			<Modal.Header
				onHide={toggleModal}
				closeButton
				className={classNames('modal-colored-header', 'bg-' + className)}
			>
				<Modal.Title className="text-light">
					{errors && Object.entries(errors).length > 0
						? 'Corrija as seguintes informações'
						: title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{errors !== undefined && Object.entries(errors).length > 0 ? (
					<>
						{Object.entries(errors).map(([campo, erros]) => (
							<div key={campo}>
								{typeof erros === 'string' ? (
									<p className="text-center red">
										{campo}: {erros}
									</p>
								) : (
									erros?.map((erro, index) => (
										<p key={index} className="text-center red">
											{campo}: {erro}
										</p>
									))
								)}
							</div>
						))}
					</>
				) : (
					<p className="text-center">{content}</p>
				)}
			</Modal.Body>
			<Modal.Footer>
				{customButtons ? (
					customButtons
				) : (
					<Button variant={className} onClick={toggleModal}>
						Fechar
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
}
