import { ApiResponse, ClientList } from '@/types';
import { HttpClient } from '@/data';
import { ConfirmData } from '@/types/ConfirmData';
import { EmployeeBill } from '@/types/Gestao';

const configPublic = {
	headers: {
		Authorization: null,
	},
};

export const ConfirmDataRepository = {
	get api() {
		return HttpClient(false);
	},

	listClients: async function (): Promise<ApiResponse<ClientList>> {
		let config = {
			params: {
				limit: 9999,
			},
		};
		return this.api.get(this.api.endpoints.clients, config);
	},

	get: async function (token: string): Promise<ApiResponse<ConfirmData>> {
		return this.api.get(`${this.api.endpoints.confirmData}/?token=${token}`, configPublic);
	},

	create: async function (data: any): Promise<ApiResponse<ConfirmData>> {
		return this.api.post(`${this.api.endpoints.createConfirmData}/`, data, configPublic);
	},

	getToEdit: async function (token: string): Promise<ApiResponse<ConfirmData>> {
		return this.api.get(`${this.api.endpoints.publicEmployeeBillUpdate}${token}`, configPublic);
	},

	update: async function (
		token: string,
		data: Partial<FormData>
	): Promise<ApiResponse<EmployeeBill>> {
		const config = {
			...configPublic,
			headers: {
				...configPublic.headers,
				'Content-Type': 'multipart/form-data',
			},
		};
		return this.api.patch(
			`${this.api.endpoints.publicEmployeeBillUpdate}${token}/`,
			data,
			config
		);
	},
};
