import { useNotificationContext } from '@/common/context';
import { useState } from 'react';
import { t } from 'i18next';
import * as yup from 'yup';
import { Errors } from '@/types/types';
import { ConfirmDataRepository } from '@/data/api/confirmData';
import { ConfirmData } from '@/types/ConfirmData';
import useLogout from '@/pages/account/Logout/useLogout';

export default function useConfirmData() {
	const [waitingApi, setWaitingApi] = useState<boolean>(false);
	const [errors, setErrors] = useState<Errors>();
	const { showNotification } = useNotificationContext();
	const [clients, setClients] = useState<Array<any> | null>(null);
	const [defaultValues, setDefaultValues] = useState<ConfirmData>();
	const [employeeAlreadyConfirmed, setEmployeeAlreadyConfirmed] = useState(false);
	const { logout } = useLogout();

	const confirmSchema = yup.object().shape({
		telefone: yup.string().required('Por favor insira Número de Telefone'),
		name: yup.string().required('Por favor insira Nome do Contrato'),
		birthdate: yup.string().required('Por favor insira a data de nascimento do titular'),
		terms_of_use: yup
			.boolean()
			.oneOf([true], 'Você deve aceitar os termos de uso')
			.required('Você deve aceitar os termos de uso'),
	});
	
	const createConfirmSchema = yup.object().shape({
		email: yup.string().email('Este e-mail é inválido').required('Por favor insira seu email'),
		name: yup.string().required('Por favor insira seu nome'),
		client: yup.number().required('Por favor insira seu local de trabalho'),
	});

	function mapErrors(response: any): void {
		let errors: any = {};
		if (response?.detail) {
			errors['detail'] = response.detail;
		}
		if (response?.message) {
			errors['message'] = response.message;
		}
		if (response?.file_csv) {
			errors['file_csv'] = response.file_csv;
		}
		if (response?.error) {
			errors['error'] = response.error;
		}

		setErrors(errors);
	}

	const getClients = async () => {
		setWaitingApi(true);
		const res = await ConfirmDataRepository.listClients();
		try {
			switch (res.status) {
				case 200:
					const data = res?.data?.results;
					setClients(data);
					setWaitingApi(false);
					break;
				default:
					mapErrors(res.data);
					showNotification({
						message: 'Erro Inesperado - ' + res.data?.message,
						type: 'error',
					});
					setWaitingApi(false);
					throw new Error(res.data?.detail || t('server_error'));
			}
		} catch (error: any) {
			console.log(error);
			setWaitingApi(false);
		}
	};

	const createConfirmData = async (data: any): Promise<ConfirmData | null> => {
		setWaitingApi(true);
		const res = await ConfirmDataRepository.create({
			...data,
			telefone: '00000000000'
		});
		try {
			switch (res.status) {
				case 200:
					return res?.data;
				default:
					mapErrors(res.data);
					showNotification({
						message: 'Erro Inesperado - ' + res.data?.message,
						type: 'error',
					});
					setWaitingApi(false);
					throw new Error(res.data?.detail || t('server_error'));
			}
		} catch (error: any) {
			console.log(error);
			setWaitingApi(false);
		}
		return null;
	};

	const getConfirmData = async (token: string) => {
		setWaitingApi(true);
		const res = await ConfirmDataRepository.get(token);
		try {
			switch (res.status) {
				case 200:
					const data = res?.data;
					if (data != null) {
						if (data.confirm_data) {
							setEmployeeAlreadyConfirmed(res?.data?.confirm_data);
						}
						if (data.birthdate && data.name && data.telefone) {
							data.terms_of_use = true;
						}
					}
					if (data.telefone === '00000000000') {
						data.telefone = null;
					}
					setDefaultValues(data);
					setWaitingApi(false);
					break;
				case 401:
					logout();
					showNotification({ message: t('token_expired'), type: 'error' });
					break;
				default:
					mapErrors(res.data);
					showNotification({
						message: 'Erro Inesperado - ' + res.data?.message,
						type: 'error',
					});
					setWaitingApi(false);
					throw new Error(res.data?.detail || t('server_error'));
			}
		} catch (error: any) {
			console.log(error);
			setWaitingApi(false);
		}
	};

	const getDataToEdit = async (id: string) => {
		setWaitingApi(true);
		const res = await ConfirmDataRepository.getToEdit(id);
		try {
			switch (res.status) {
				case 200:
					setDefaultValues(res.data);
					setWaitingApi(false);
					break;
				case 401:
					logout();
					showNotification({ message: t('token_expired'), type: 'error' });
					break;
				default:
					mapErrors(res.data);
					showNotification({
						message: 'Erro Inesperado - ' + res.data?.message,
						type: 'error',
					});
					setWaitingApi(false);
					throw new Error(res.data?.detail || t('server_error'));
			}
		} catch (error: any) {
			console.log(error);
			setWaitingApi(false);
		}
	};

	return {
		confirmSchema,
		createConfirmSchema,
		errors,
		setErrors,
		waitingApi,
		defaultValues,
		employeeAlreadyConfirmed,
		clients,
		getClients,
		getConfirmData,
		getDataToEdit,
		createConfirmData,
	};
}
