import { useState, useEffect, FC } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { CheckInput, Form as RHForm, TextInput, DateInput, FormInput } from '@/components';
import { useModal } from '@/hooks';
import ModalMessage from '@/components/Modal/modalMessage';
import useConfirmData from './hooks/useConfirmData';
import { t } from 'i18next';
import { ConfirmData } from '@/types/ConfirmData';
import { Errors } from '@/types';

interface Props {
	layoutValues?: (data?: any) => void;
	confirmData?: (data?: any) => void;
	token?: string;
}

const FormConfirm: FC<Props> = ({ confirmData = () => {}, layoutValues = () => {}, token }) => {
	const { isOpen, className, openModalWithClass, closeModal } = useModal();
	const [loading, setLoading] = useState<boolean>(true);
	const [hasErrors, setHasErrors] = useState<boolean>(false);
	const {
		getConfirmData,
		errors,
		setErrors,
		confirmSchema,
		defaultValues,
		waitingApi,
		employeeAlreadyConfirmed,
	} = useConfirmData();
	const [startDate, setStartDate] = useState<Date>(new Date('1990-01-01'));

	useEffect(() => {
		if (errors !== undefined && Object.keys(errors).length !== 0) {
			const invalidInputs = document.querySelectorAll('.is-invalid');
			if (invalidInputs.length > 0) {
				invalidInputs[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
			setTimeout(() => {
				setHasErrors(true);
				openModalWithClass('danger');
			}, 1000);
		} else {
			setHasErrors(false);
		}
	}, [errors]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (token) {
					await getConfirmData(token);
				}
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		layoutValues(defaultValues);
		if (employeeAlreadyConfirmed) {
			let dataWithuseTerms: ConfirmData | { terms_of_use?: boolean } = defaultValues
				? defaultValues
				: {};
			dataWithuseTerms.terms_of_use = true;
			confirmData(dataWithuseTerms);
		}
	}, [employeeAlreadyConfirmed, defaultValues]);

	const handleChangeDate = (date: Date) => {
		setStartDate(date);
	};

	const handleFieldChange = (fieldName: keyof Errors) => {
		if (errors && errors[fieldName]) {
			const newApiErrors = { ...errors };
			delete newApiErrors[fieldName];
			setErrors(newApiErrors);
		}
	};

	return (
		<>
			{!loading && (
				<RHForm
					onSubmit={(e) => {
						if (e) {
							if (e.terms_of_use == true) {
								let dataWithuseTerms: ConfirmData | { terms_of_use?: boolean } =
									defaultValues ? defaultValues : {};
								dataWithuseTerms = e;
								confirmData(dataWithuseTerms);
							}
						}
					}}
					schema={confirmSchema}
					defaultValues={defaultValues}
				>
					<div className="inputs">
						<TextInput
							type="text"
							name="name"
							label="Nome Completo"
							placeholder="João da Silva"
							containerClass={'mb-3'}
							disabled={true}
						/>
						<FormInput
							name="telefone"
							label="Telefone"
							placeholder="(99)99999-9999"
							type="phone_number"
							containerClass={'mb-3'}
							errors={errors}
							onChangeCustom={() => handleFieldChange('cpf')}
						/>
						<DateInput
							label="Data de Nascimento"
							name="birthdate"
							className="form-control"
							value={startDate}
							onChange={(date) => handleChangeDate(date)}
							containerClass={'mb-3'}
							errors={errors}
							type={'date'}
						/>
						{errors && <h4 className="red text-center">{errors['error']}</h4>}
					</div>

					<div className="checkbox_btn">
						<CheckInput
							name="terms_of_use"
							type="checkbox"
							containerClass="mb-2"
							label={
								<>
									Concordo com os&nbsp;
									<span className="text-muted cursor-pointer">
										<a
											href={defaultValues?.terms_link_url || '#'}
											target="_blank"
											rel="noopener noreferrer"
										>
											termos do benefício
										</a>
									</span>
								</>
							}
							defaultChecked={defaultValues?.terms_of_use ?? false}
						/>
						<Button
							id="btnSubmit"
							type="submit"
							variant="btnColor"
							disabled={hasErrors || waitingApi}
						>
							{waitingApi ? (
								<Spinner className="text-primary m-2" color="primary" size={'sm'} />
							) : (
								<>Solicitar Benefício</>
							)}
						</Button>
					</div>
				</RHForm>
			)}
			<ModalMessage
				isOpen={isOpen}
				toggleModal={className == 'success' ? () => {} : closeModal}
				className={className}
				title={
					className == 'success'
						? t('pages.cliente.gestaoColaboradores.form.modal.success')
						: t('pages.cliente.gestaoColaboradores.form.modal.error')
				}
				errors={errors}
			/>
		</>
	);
};

export default FormConfirm;
